export const actionTypes = {
  LOCK: 'service/LOCK',
  UNLOCK: 'service/UNLOCK'
};

export const selectors = {
  serviceLock: state => state.service.lock,
};

// Declare actions
export const actions = {
  switchLock: value => ({
    type: value ? actionTypes.LOCK : actionTypes.UNLOCK,
  })
};