import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import globals from 'src/globals.js';
import produce from 'immer';

// TODO enhance local storage security
const storage = window && !USE_TEMP_STORAGE
  ? localStorage
  : {
    data: {},
    getItem: function (key) { return this.data[key] },
    setItem: function (key, value) { this.data[key] = value },
    removeItem: function (key) { delete this.data[key] },
  };

export const serviceIndicatorActions = {
  CALL: 'service/CALL',
  RESPONSE: 'service/RESPONSE',
  ERROR: 'service/ERROR'
}
let axiosMock = null;
if (!SERVICE_MOCK) {
  axios.defaults.baseURL = SERVICE_BASE_URL;

  axios.interceptors.request.use(function (request) {
    if (globals.Store) {
      globals.Store.dispatch({ type: serviceIndicatorActions.CALL });
    }
    return request;
  }, function (error) {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    if (globals.Store) {
      globals.Store.dispatch({ type: serviceIndicatorActions.RESPONSE });
    }
    return response;
  }, function (error) {
    if (globals.Store) {
      globals.Store.dispatch({ type: serviceIndicatorActions.ERROR });
    }
    return Promise.reject(error);
  });

} else {
  axiosMock = new MockAdapter(axios, { delayResponse: 1500 });
  axios.interceptors.request.use(request => {
    console.debug('request:', request)
    return request
  });
  axios.interceptors.response.use(response => {
    console.debug('Response:', response)
    return response
  });
}

export const reducer = {
  service: produce((service, action) => {
    const { type, payload } = action;
    if (type == serviceIndicatorActions.CALL) {
      service.count = service.count + 1;
    }
    if (type == serviceIndicatorActions.RESPONSE) {
      service.count = service.count - 1;
    }
    if (type == serviceIndicatorActions.ERROR) {
      service.count = service.count - 1;
    }
  }, {})
};

export const selectors = {
  serviceCall: state => state.service.count !== 0,
};

export const initialiseServices = async (modules) => {
  const moduleServices = await Promise.all(modules.map(async moduleName => {
    return {
      moduleName,
      module: await import(/* webpackMode: "eager" */`src/${moduleName}/services.js`).catch(error => {})
    };
  }));

  const services = moduleServices.reduce((accumulatedServices, { moduleName, module }) => {
    if (module) {
      module.getServices && (
        accumulatedServices[moduleName] = module.getServices(axios, storage)
      )
    }
    return accumulatedServices;
  }, {
    direct: (params, relativePath) => {
      const urlParams = new URLSearchParams();
      const token = storage.getItem('authToken');
      params['signature'] = getSignature(params, token);
      for (const [key, value] of Object.entries(params)) {
        urlParams.set(key, value);
      }
      window.open(axios.defaults.baseURL + relativePath + '?' + urlParams.toString());
    }
  });
  return services;
};


export const initialiseStubs = async (modules) => {
  if (axiosMock) {
    const stubs = await Promise.all(modules.map(async moduleName => {
      return {
        moduleName,
        module: await import(`src/${moduleName}/stubs.js`).catch(error => {})
      };
    }));

    for (
      /* JavaScript - Yes */
      const stubModule of stubs.filter(module => module.module && module.module.hasOwnProperty('injectStubs'))
    ) {
      const { moduleName, module } = stubModule;
      module.injectStubs(axiosMock);
    }
  }
};