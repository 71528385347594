import React from 'react';
import { Spin, Space, Modal as AntModal, Row, Col } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { selectors } from 'src/common/store.js';

const ComponentMessages = {
  visitLink: ({ url }) => <>Visit <a href={url}>{url}</a> to check.</>,
  returnToMerchant: ({ url }) => <>You will be returned to <a href={url}>{url}</a>.</>
};

export const Modal = (props) => {

  const { isVisible, message, title, type, messageParams } = props;

  return (<AntModal closable={false} footer={false} title={title} visible={isVisible} /*onOk={handleOk} onCancel={handleCancel}*/>
    <Row gutter={10} align='middle'>
      <Col><Space size="middle">
        {type == 'spinner' && <Spin size="large" />}
        {type == 'warning' && <ExclamationCircleOutlined style={{ fontSize: '2em', color: 'orange' }} />}
        {type == 'error' && <ExclamationCircleOutlined style={{ fontSize: '2em', color: 'red' }} />}
        {type == 'success' && <CheckCircleOutlined style={{ fontSize: '2em', color: 'green' }} />}
      </Space></Col>
      <Col>{message in ComponentMessages ? ComponentMessages[message](messageParams) : message}</Col>
    </Row>
  </AntModal>);
};


export const ModalConnected = connect((state, props) => {
  const newProps = { isVisible: selectors.showModal(state), ...selectors.modalProps(state) };
  return newProps;
})(Modal);

export default ModalConnected;