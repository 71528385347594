import { initialiseServices } from 'src/services.js';
import { initialiseStubs } from 'src/services.js';
import { initialiseRouter } from 'src/router.js';
import { initialiseStore } from 'src/store.js';
import globals from 'src/globals.js';

export const initApp = async (modules) => {
  await initialiseStubs(modules);
  await initialiseRouter(modules).then(router => {
    globals.Router = router.routerInstance;
    globals.RouteNames = router.routeNames;
    globals.RouteDefs = router.routeDefs;
    globals.ModuleMaps = router.moduleMaps;
  });
  await initialiseServices(modules).then(services => {
    globals.Services = services;
  });
  await initialiseStore(modules).then(([store, startSagas]) => {
    globals.Store = store;
    startSagas();
  });
}

export default globals;

