
// Services
export const getServices = (remoteAdapter, storageAdapter) => {
  return {
    getToken: (username, password) => {
      return remoteAdapter.post('/auth/token', {
        username,
        password
      });
    },
    getPrmList: (token) => {
      return remoteAdapter.get('/prm', {
        params: {
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
  };
};