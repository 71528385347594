// returnUrl, prmId, qrCode

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Divider, Button, Card, Input, Select, AutoComplete, Space } from 'antd';
import { renderQrCode } from 'src/statuscheck/functions.js';
import { connect } from 'react-redux';
import { Requisites } from 'src/statuscheck/Requisites.js';
import Logo from 'assets/inopay.png';
import { actions, selectors } from 'src/statuscheck/store.js';
import { filter, uniq } from 'lodash';

export function StatusPage(props) {

  const { dispatch } = props;
  const { prmId, amount, currency, merchantUrl, qrCodeSrc, buyerEmail } = props;

  const [customEmail, setCustomEmail] = useState(null);

  const emailOptions = uniq(filter([
    buyerEmail,
    customEmail
  ])).map(email => ({ value: email }));

  const qrCodeRef = useRef(null);

  useMemo(
    () => {
      if (qrCodeSrc && qrCodeRef.current) {
        renderQrCode(qrCodeSrc, qrCodeRef.current);
      }
    },
    [qrCodeSrc, qrCodeRef.current]
  );
  return <Row>
    {/* Calc span+offset */}
    <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 14, offset: 5 }} lg={{ span: 10, offset: 6 }} xl={{ span: 8, offset: 8 }} xxl={{ span: 6, offset: 9 }}>
      <Card>
        <Row justify="space-between">
          <Col>
            <img style={{ width: "2em" }} src={Logo} />
            <p>Payment</p>
          </Col>
          <Col>
            <Row>
              <Col>{amount} {currency}</Col>
            </Row>
            <Row>
              <Col>{merchantUrl}</Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-around" align="middle">
          <Col>
            <Row justify="space-around" align="middle">
              <Col> <img ref={qrCodeRef}></img></Col>
            </Row>
            <Row justify="space-around" align="middle">
              <Col>
                <Button type="primary" onClick={event => dispatch(actions.pay(prmId))} >Pay now</Button>
              </Col>
              <Col>
                <Button type="primary" ghost={true} onClick={event => dispatch(actions.payLater(prmId))} >Pay later</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-around" align="middle">
          {buyerEmail && <Col>
            <Input.Group compact>
              <AutoComplete
                onSearch={text => { setCustomEmail(text) }}
                onSelect={text => { setCustomEmail(text) }}
                defaultValue={buyerEmail}
                style={{ width: '12em' }}
                placeholder="Email"
                options={emailOptions}
              />
              <Button disabled={customEmail !== null && !customEmail} onClick={event => dispatch(actions.sendMail(prmId, customEmail || buyerEmail))}>Ask friend</Button>
            </Input.Group>
          </Col>}
          <Col>
            <Button onClick={event => dispatch(actions.cancel(prmId))}>Cancel</Button>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <Requisites />
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>;
};

export const StatusPageConnected = connect((state, props) => {
  return { ...selectors.params(state) };
})(StatusPage);

export default StatusPageConnected;
