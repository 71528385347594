import { all, call, put, select, delay, take } from 'redux-saga/effects';
import produce from 'immer';
import { sagaRunner } from 'src/store.js';
import { get, last, first } from 'lodash';
import globals from 'src/init.js';

// Declare all action types
export const actionTypes = {
  SHOW_MODAL: 'common/SHOW_MODAL',
  HIDE_MODAL: 'common/HIDE_MODAL'
};


// Auth actions
export const actions = {
  showModal: modalProps => ({
    type: actionTypes.SHOW_MODAL,
    payload: { showModal: true, ...modalProps }
  }),
  hideModal: () => ({
    type: actionTypes.HIDE_MODAL,
    payload: {}
  })
};

// Declare selectors
export const selectors = {
  modalProps: state => get(state, 'modal.props') || {},
  showModal: state => get(state, 'modal.props.showModal')
};

// Self service reducer, should be wrapped by immer produce
export const reducer = {
  modal: produce((modal, action) => {
    const { type, payload } = action;
    if (type == actionTypes.SHOW_MODAL) {
      modal.props = payload;
    } else if (type == actionTypes.HIDE_MODAL) {
      modal.props = {}
    }
  }, {})
};

// Initial state
export const initial = {
  modal: {
    props: {}
  }
};