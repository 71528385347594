import { merge } from 'lodash';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { router5Middleware, router5Reducer } from 'redux-router5';
import createSagaMiddleware from 'redux-saga';
import { all, put, cancel } from 'redux-saga/effects';
import globals from 'src/init.js';
import { startRouter } from 'src/router.js';
import { actions as routerActions } from 'redux-router5';
import { actionTypes as serviceActionTypes } from 'src/service/store.js';
import { reducer } from 'src/services.js';

// For sagas which use blocking take and don't handle exceptions
export function* sagaRunner(sagaBody) {
  while (true) {
    try {
      yield sagaBody();
    } catch (error) {
      // Handle network error
      if (error.response === undefined && 'request' in error) {
        console.error('Network error: ' + error);
        yield put({ type: serviceActionTypes.NETWORK_ERROR, payload: true });
      } else {
        // rethrow other errors
        throw error;
      }
    }
  }
}

export async function initialiseStore(modules) {
  const moduleStores = await Promise.all(modules.map(moduleName => {
    return import(/* webpackMode: "eager" */`src/${moduleName}/store.js`).catch(error => { });
  }));

  const storeDeps = moduleStores.reduce((accumulatedStoreDeps, moduleStore) => {
    if (moduleStore) {
      moduleStore.initial && merge(accumulatedStoreDeps.initialState, moduleStore.initial)
      merge(accumulatedStoreDeps.reducers, moduleStore.reducer);
      moduleStore.sagas && accumulatedStoreDeps.sagas.push(moduleStore.sagas());
    }
    return accumulatedStoreDeps;
  }, {
    initialState: {
      service: {
        count: 0
      }
    },
    reducers: {},
    sagas: []
  });


  // Initialise reducer
  const rootReducer = combineReducers({
    ...storeDeps.reducers,
    ...reducer,
    router: router5Reducer,
  });

  // Initialise root saga
  const rootSaga = function* () {
    while (true) {
      try {
        yield all([
          ...storeDeps.sagas,
          startRouter()
        ]);
      } catch (error) {
        console.error('Error in root saga: ' + error);
        console.error(error.stack);
        // Error was not handled in nested sagas
        if (error.response === undefined && 'request' in error) {
          console.error('Network error: ' + error);
          yield put({ type: serviceActionTypes.NETWORK_ERROR, payload: true });
        } else if (error.response && error.response.status === 404) {
          yield put(routerActions.navigateTo(globals.RouteNames.NOT_FOUND, {}));
        } else if (error.response && error.response.status === 500) {
          yield put(routerActions.navigateTo(globals.RouteNames.SERVICE_ERROR, {
            code: error.response && error.response.status,
            message: error.response && error.response.statusText
          }));
        } else {
          yield put(routerActions.navigateTo(globals.RouteNames.APPLICATION_ERROR, {
            message: error.message
          }));
        }
      }
    }
  };

  // Declate middlewares
  const sagaMiddleware = createSagaMiddleware();
  const logger = createLogger({});
  const middlewares = [
    logger,
    router5Middleware(globals.Router),
    sagaMiddleware
  ];

  // Create store
  // TODO update deprecated
  const storeInstance = createStore(
    rootReducer, storeDeps.initialState, applyMiddleware(...middlewares)
  );

  return [storeInstance, () => sagaMiddleware.run(rootSaga)];
};
