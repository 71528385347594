
// Services
export const getServices = (remoteAdapter, storageAdapter) => {
  return {
    getStatus: (prmId) => {
      return remoteAdapter.get('/status/' + prmId, {
        params: {
        }
      });
    },
    payNow: (prmId) => {
      // TODO device adapter?
      window && window.location.replace(`${INOPAY_BASE_URL}/rtp/confirm?prmid=*${prmId}`);
    },
    payLater: (prmId, email) => {
      window && window.location.replace(`${INOPAY_BASE_URL}/rtp/receive?prmid=*${prmId}`);
    },
    // ask friend
    sendMail: (prmId, email) => {
      return remoteAdapter.get(`/sendemail/${prmId}`, {
        params: { email }
      });
      /*
      return remoteAdapter.post('/postpone/' + prmId, {
        email
      });
      */
    },
    cancel: (prmId) => {
      return remoteAdapter.get(`/cancel/${prmId}`, {
      });
    },
    returnToMerchant: (url) => {
      window && window.location.replace(url);
    }
  };
};