import { all, call, put, select, delay, take } from 'redux-saga/effects';
import produce from 'immer';
import { sagaRunner } from 'src/store.js';
import { get, last, first } from 'lodash';
import globals from 'src/init.js';
import { actions as commonActions } from 'src/common/store.js';

// Declare all action types
export const actionTypes = {
  PARAMS: 'statuscheck/PARAMS',
  GET_STATUS: 'statuscheck/GET_STATUS',
  PAY_NOW: 'payment/PAY_NOW',
  PAY_LATER: 'payment/PAY_LATER',
  SEND_MAIL: 'payment/SEND_MAIL',
  CANCEL: 'payment/CANCEL'
};


// Auth actions
export const actions = {
  pay: prmId => ({
    type: actionTypes.PAY_NOW,
    payload: { prmId }
  }),
  payLater: prmId => ({
    type: actionTypes.PAY_LATER,
    payload: { prmId }
  }),
  sendMail: (prmId, email) => ({
    type: actionTypes.SEND_MAIL,
    payload: { prmId, email }
  }),
  cancel: prmId => ({
    type: actionTypes.CANCEL,
    payload: { prmId }
  })
};

// Declare selectors
export const selectors = {
  params: state => get(state, 'params.initial') || {},
  prm: state => get(state, 'params.initial.prmId')
};

// Self service reducer, should be wrapped by immer produce
export const reducer = {
  params: produce((params, action) => {
    const { type, payload } = action;
    if (type == actionTypes.PARAMS) {
      params.initial = payload;
    }
  }, {})
};

// Initial state
export const initial = {
  params: {
    requisites: {}
  }
};


// Sagas
export function* sagas() {
  yield all([
    loadParamsSaga(),
    sagaRunner(payNowSaga),
    sagaRunner(payLaterSaga),
    sagaRunner(sendMailSaga),
    sagaRunner(cancelSaga)
  ]);
}

function* loadParamsSaga() {
  const prmId = first(last(window.location.href.split('?')).split('&'));
  try {
    yield put(commonActions.showModal({
      title: 'Loading',
      type: 'spinner',
      message: 'Please wait...'
    }));
    const statusResponse = yield call(globals.Services.statuscheck.getStatus, prmId);
    if (prmId && statusResponse.status == 200) {
      yield put({
        type: actionTypes.PARAMS, payload: { prmId, ...statusResponse.data }
      });
      yield put(commonActions.hideModal());
      yield sagaRunner(statusCheckSaga);
    } else if (statusResponse.status == 404 || statusResponse.status == 400) {
      yield put(commonActions.showModal({
        title: 'Payment not found or expired',
        type: 'warning',
        message: 'visitLink'
      }));
    }
  } catch (error) {
    yield put(commonActions.showModal({
      title: 'Failed to load payment details',
      type: 'error',
      message: error.message
    }));
  }
}

function* statusCheckSaga() {
  const prmId = yield select(selectors.prm);
  const statusHandlers = {
    payed: () => { },
    canceled: () => { },
    accepted: () => { },
    rejected: () => { }
  };
  const delayFor = 5 * 1000;
  yield delay(delayFor);
  if (prmId) {
    const { returnUrl } = yield select(selectors.params);
    const statusResponse = yield call(globals.Services.statuscheck.getStatus, prmId);
    if (statusResponse.status == 200 && statusResponse.data.status in statusHandlers) {
      yield put(commonActions.showModal({
        title: `Payment finalized with status: ${statusResponse.data.status}`,
        type: 'success',
        message: 'returnToMerchant',
        messageParams: { url: returnUrl }
      }));
      yield delay(4 * 1000);
      yield call(globals.Services.statuscheck.returnToMerchant(returnUrl));
    }
  }
}

function* payNowSaga() {
  const action = yield take(actionTypes.PAY_NOW);
  yield call(globals.Services.statuscheck.payNow, action.payload.prmId);
}
function* payLaterSaga() {
  const action = yield take(actionTypes.PAY_LATER);
  yield call(globals.Services.statuscheck.payLater, action.payload.prmId);
}

function* sendMailSaga() {
  const action = yield take(actionTypes.SEND_MAIL);
  const { prmId, email } = action.payload;
  const { returnUrl } = yield select(selectors.params);
  yield put(commonActions.showModal({
    title: 'Sending mail',
    type: 'spinner',
    message: 'Please wait...'
  }));
  const response = yield call(globals.Services.statuscheck.sendMail, prmId, email);
  if (response.status == 200) {
    yield put(commonActions.showModal({
      title: 'Mail sent',
      type: 'success',
      message: 'returnToMerchant',
      messageParams: { url: returnUrl }
    }));
    yield delay(4 * 1000);
    yield call(globals.Services.statuscheck.returnToMerchant(returnUrl));
  }
}

function* cancelSaga() {
  const action = yield take(actionTypes.CANCEL);
  yield put(commonActions.showModal({
    title: 'Payment is being cancelled',
    type: 'spinner',
    message: 'Please wait...'
  }));
  const { returnUrl } = yield select(selectors.params);
  const response = yield call(globals.Services.statuscheck.cancel, action.payload.prmId);
  if (response.status == 200) {
    yield put(commonActions.showModal({
      title: 'Payment canceled',
      type: 'success',
      message: 'returnToMerchant',
      messageParams: { url: returnUrl }
    }));
    yield delay(4 * 1000);
    yield call(globals.Services.statuscheck.returnToMerchant(returnUrl));
  }
}