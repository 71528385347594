import { all, call, put, select, take, delay } from 'redux-saga/effects';
import produce from 'immer';
import { sagaRunner } from 'src/store.js';
import globals from 'src/init.js';

// Declare all action types
export const actionTypes = {
  CREDENTIALS_CHANGED: 'gwconfig/CREDENTIALS_CHANGED',
  //PRM_LIST: 'gwconfig/PRM_LIST',
  SET_PRM_LIST: 'payment/SET_PRM_LIST'
};

export const actions = {
  credentialsChanged: (name, value) => {
    return {
      type: actionTypes.CREDENTIALS_CHANGED,
      payload: { [name]: value }
    }
  }
};

// Declare selectors
export const selectors = {
  prmList: state => state.prm.list || null,
  credentials: state => state.prm.credentials,
};

// Self service reducer, should be wrapped by immer produce
export const reducer = {
  prm: produce((prm, action) => {
    const { type, payload } = action;
    if (type == actionTypes.SET_PRM_LIST) {
      prm.list = payload;
    } else if (type == actionTypes.CREDENTIALS_CHANGED) {
      prm.credentials = {
        ...prm.credentials,
        ...payload
      }
    }
  }, {}),
};

// Initial state
export const initial = {
  prm: {
    list: null,
    credentials: {
    }
  }
};


// Sagas
export function* sagas() {
  yield all([
    sagaRunner(crediantialsWatcher),
    initialCredentialsCompat()
  ]);
}

function* crediantialsWatcher() {
  yield take(actionTypes.CREDENTIALS_CHANGED);
  // TODO compare with old credentials
  const credentials = yield select(selectors.credentials);
  if (credentials.merchantToken) {
    try {
      const prmListResponse = yield call(globals.Services.gwconfig.getPrmList, credentials.merchantToken);
      if (prmListResponse.status == 200 && prmListResponse.data.prms) {
        yield put({ type: actionTypes.SET_PRM_LIST, payload: prmListResponse.data.prms });
      }
    } catch (error) {
      console.error('Prm loader error: ' + error);
      console.error(error.stack);
    }
  }
}

function* initialCredentialsCompat() {
  let credentialsFound = false;
  while (!credentialsFound) {

    try {
      const merchantToken = document.querySelector("[data-name=merchantToken]");

      if (merchantToken.value) {
        credentialsFound = true;
        yield put(actions.credentialsChanged('merchantToken', merchantToken.value));
      }

    } catch (error) {
      console.error('Initial credentials: ' + error);
      console.error(error.stack);
    }
    yield delay(1000);
  }

}