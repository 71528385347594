import React from 'react';
import ReactDom from 'react-dom/client';
import { Provider } from 'react-redux';
import globals, { initApp } from 'src/init.js';
import 'src/polyfills.js';
import 'src/statuscheck/assets.js';
import StatusPage from 'src/statuscheck/StatusPage.js';
import Modal from 'src/common/components/Modal.js';

// App attachment node
const rootNode = document.getElementsByTagName('body')[0];

// App modules
const modules = [
  'common', 'statuscheck'
];

// React
const root = ReactDom.createRoot(rootNode);
initApp(modules).then(() => {
  root.render(
    <Provider store={globals.Store}>
      <Modal />
      <StatusPage />
    </Provider>
  );
});