import QRCode from 'qrcode';


export function renderQrCode(qrCodeSrc, imgRef) {
    imgRef.setAttribute("src", `data:image/png;base64, ${qrCodeSrc}`);
    /*
    QRCode.toCanvas(canvasRef, qrCodeSrc, function (error) {
        if (error) {
            console.error(error);
        }
    });*/
}