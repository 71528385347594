import { createIntl } from 'react-intl';
import { call, put, select, all, take, delay, race } from 'redux-saga/effects';
import produce from 'immer';
import {
  actions as authActions,
  actionTypes as authActionTypes,
  selectors as authSelectors
} from 'src/auth/store.js';
import { get } from 'lodash';
import globals from 'src/init.js';
import { serviceIndicatorActions } from 'src/services.js';
import { sagaRunner } from 'src/store.js';
import { NotIdle } from 'idlejs';

// Declare all action types
const actionTypes = {
  SET_PAGE: 'root/SET_PAGE',
  SET_LOCALES: 'root/SET_LOCALES',
  SET_MESSAGES: 'root/SET_MESSAGES',
  ALIVE: 'root/ALIVE',
  AFK_CHECK: 'root/AFK',
};

// Declare actions
export const actions = {
  setPage: (module) => ({
    type: actionTypes.SET_PAGE,
    payload: module,
  }),
  setLocales: ({ language, datetime }) => ({
    type: actionTypes.SET_LOCALES,
    payload: { language, datetime },
  }),
  alive: () => ({
    type: actionTypes.ALIVE,
  })
};


// Declare selectors
export const selectors = {
  languageChoices: state => Object.keys(state.root.localeChoices),
  currentPage: state => state.root.currentPage,
  currentRoute: state => state.router.route,
  locales: state => state.root.locales,
  currentLanguage: state => state.root.locales['language'],
  messages: state => state.root.messages,
  datetimeIntl: state => state.root.datetimeIntl,
  afkCheck: state => state.root.showAfk,
  afkDialogTimeout: state => state.root.afkDialogTimeout,
  isLoaded: state => state.root.serviceCounter === 0,
};


// Declare reducer, should be wrapped by immer produce
export const reducer = {
  root: produce((root, action) => {
    const { type, payload } = action;
    if (type === actionTypes.SET_PAGE) {
      root.currentPage = payload;
    }
    if (type === actionTypes.SET_LOCALES) {
      root.locales = payload;
    }
    if (type === actionTypes.SET_MESSAGES) {
      root.messages = payload.messages;
      root.datetimeIntl = payload.datetimeIntl;
      root.locales = {
        ...root.locales,
        ...payload.locales
      };
    }
    if (type === actionTypes.ALIVE || type === authActionTypes.LOGOUT) {
      delete root.showAfk;
    }
    if (type === actionTypes.AFK_CHECK) {
      root.showAfk = true;
      root.afkDialogTimeout = payload;
    }
    if (type === serviceIndicatorActions.CALL) {
      root.serviceCounter = root.serviceCounter + 1;
    }
    if (type === serviceIndicatorActions.RESPONSE) {
      root.serviceCounter = root.serviceCounter - 1;
    }
    if (type === serviceIndicatorActions.ERROR) {
      root.serviceCounter = root.serviceCounter - 1;
    }
  }, {})
};

// Initial state
export const initial = {
  root: {
    localeChoices: {
      en: {
        language: 'en',
        datetime: 'en',
        dateUtilsLocaleName: 'en-US',
      },
      lt: {
        language: 'lt',
        datetime: 'lt',
        dateUtilsLocaleName: 'lt',
      }
    },
    locales: {
      language: 'lt',
      datetime: 'lt',
      dateUtilsLocaleName: 'lt',
      dateUtilsLocale: null,
    },
    serviceCounter: 0,
  }
};

// Declare sagas

// Auth sagas
export function* sagas() {
  yield all([
    rootSaga(),
    sagaRunner(localeSaga),
    afkSaga(),
    sagaRunner(afkDialogSaga),
  ]);
}

const intlCache = {
  language: {},
  datetime: {}
};

export function* rootSaga() {
  const { language, datetime, dateUtilsLocaleName } = yield select(selectors.locales);
  const importMessages = () => {
    return Promise.all([
      import(`translations/${language}.js`),
      import(`translations/datetime/${datetime}.js`),
      //import(`date-fns/locale/${dateUtilsLocaleName}/index.js`),
    ]);
  };
  const [languageMessages, datetimeMessages, dateUtilsLocale] = yield call(importMessages);
  yield put({
    type: actionTypes.SET_MESSAGES,
    payload: {
      messages: languageMessages.default,
      datetimeIntl: createIntl({
        locale: datetime,
        messages: datetimeMessages.default,
      }, intlCache.datetime),
      locales: {
        dateUtilsLocale: dateUtilsLocale,
      },
    }
  });
}

export function* localeSaga() {
  const [localeChangeAction, currentLanguage] = yield all([
    take([
      authActionTypes.LOGIN_SUCCESS,
      authActionTypes.UPDATE_USER_SETTINGS,
      authActionTypes.LOAD_PROFILE
    ]),
    select(selectors.currentLanguage)
  ]);
  const profile = yield select(authSelectors.profile);
  const newLanguage = authActionTypes.LOGIN_SUCCESS == localeChangeAction.type
    ? get(profile, ['settings', 'language'], currentLanguage)
    : get(localeChangeAction, ['payload', 'settings', 'language']);
  if (newLanguage && newLanguage != currentLanguage) {
    const { language, datetime, dateUtilsLocaleName } = initial.root.localeChoices[newLanguage];
    const importMessages = () => {
      return Promise.all([
        import(`translations/${language}.js`),
        import(`translations/datetime/${datetime}.js`),
        //import(`date-fns/locale/${dateUtilsLocaleName}/index.js`),
      ]);
    };
    const [languageMessages, datetimeMessages, dateUtilsLocale] = yield call(importMessages);
    yield put({
      type: actionTypes.SET_MESSAGES,
      payload: {
        messages: languageMessages.default,
        datetimeIntl: createIntl({
          locale: datetime,
          messages: datetimeMessages.default,
        }, intlCache.datetime),
        locales: {
          dateUtilsLocale: dateUtilsLocale,
          language, datetime, dateUtilsLocaleName
        },
      }
    });
    // update profile in local storage and state
    const currentProfile = yield call(globals.Services.auth.getProfile);
    if (currentProfile) {
      const newProfile = {
        ...currentProfile,
        settings: {
          ...currentProfile.settings,
          language: newLanguage
        }
      };
      yield all([
        call(globals.Services.auth.storeProfile, newProfile),
        put({ type: authActionTypes.LOAD_PROFILE, payload: newProfile }),
      ]);
    } else {
      // reload only in app state
      yield put({
        type: authActionTypes.LOAD_PROFILE, payload: {
          settings: {
            language: newLanguage
          }
        }
      });
    }
  }
}


export function* afkSaga() {
  const afkTimeout = 5 * 60 * 1000;
  const dialogTimeout = 60 * 1000;
  let afk = true;
  const idle = new NotIdle()
    .whenInteractive()
    .within(5000, 1)
    .do(() => {
      afk = false;
    }).start();
  yield sagaRunner(function* () {
    const timedOut = yield delay(afkTimeout);
    const isAuthrized = yield select(authSelectors.isAuthrized);
    if (afk && isAuthrized) {
      yield put({ type: actionTypes.AFK_CHECK, payload: dialogTimeout });
    }
    afk = true;
  });

}

export function* afkDialogSaga() {
  const afkCheckAction = yield take(actionTypes.AFK_CHECK);
  // wait for diaglog confirmation
  const [expire, confirmAlive] = yield race([
    delay(afkCheckAction.payload),
    take(actionTypes.ALIVE)
  ]);
  if (!confirmAlive) {
    yield put(authActions.logout());
  }
}

export default actions;