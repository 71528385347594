
import React from 'react';
import { Row, Col } from 'antd';

export function Requisites(props) {

  return <>
    <Row justify="space-around" align="middle">
      <Col><a href="tel:+37061111111">+37061111111</a></Col>
    </Row>
    <Row justify="space-around" align="middle">
      <Col><a href="email:info@sis.lt">info@sis.lt</a></Col>
    </Row>
  </>

};

export default Requisites;
