import { get, merge } from 'lodash';
import { actions as routerActions } from 'redux-router5';
import { all, call, cancel, cancelled, fork, put, select, take, delay } from 'redux-saga/effects';
import { actions as serviceActions } from 'src/service/store.js';
import globals from 'src/init.js';
import produce from 'immer';
import { sagaRunner } from 'src/store.js';
import { actionTypes as serviceActionTypes } from 'src/service/store.js';
import { actionTypes as routerActionTypes } from 'redux-router5';

// Declare all action types
export const actionTypes = {
  LOGIN_START: 'auth/LOGIN',
  LOGIN_ERROR: 'auth/LOGIN_ERROR',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGOUT: 'auth/LOGOUT',
  TOKEN_LOAD: 'auth/TOKEN_LOAD',
  TOKEN_UNLOAD: 'auth/TOKEN_UNLOAD',
  READY: 'auth/READY',
  LOAD_PERMISSIONS: 'auth/LOAD_PERMISSIONS',
  LOAD_PROFILE: 'auth/LOAD_PROFILE',
  UPDATE_USER_SETTINGS: 'auth/UPDATE_USER_SETTINGS',
  UPDATE_USER_PASSWORD: 'auth/UPDATE_USER_PASSWORD',
  REQUEST_LOGIN_URL: 'auth/REQUEST_LOGIN_URL',
};


// Auth actions
export const actions = {
  login: (username, password, next) => ({
    type: actionTypes.LOGIN_START,
    payload: { username, password, next },
  }),
  loginUsingToken: (tokenId, next) => ({
    type: actionTypes.LOGIN_START,
    payload: { tokenId, next },
  }),
  logout: () => ({
    type: actionTypes.LOGOUT,
  }),
  tokenLoad: (token, userId) => ({
    type: actionTypes.TOKEN_LOAD,
    payload: { token, userId },
  }),
  tokenUnload: () => ({
    type: actionTypes.TOKEN_UNLOAD,
  }),
  authReady: () => ({
    type: actionTypes.READY,
  }),
  updateUserSettings: (settings) => ({
    type: actionTypes.UPDATE_USER_SETTINGS, payload: settings
  }),
  updateUserPassword: (password) => ({
    type: actionTypes.UPDATE_USER_PASSWORD, payload: password
  }),
  requestLoginUrl: email => ({
    type: actionTypes.REQUEST_LOGIN_URL, payload: email,
  }),
};


// Auth selectors
export const selectors = {
  isAuthrized: state => {
    return state.auth.authorized === true
  },
  next: state => {
    return get(state.router, 'route.params.next');
  },
  profile: state => state.auth.profile,
  permissions: state => state.auth.permissions,
};


// Auth reducer, should be wrapped by immer produce
export const reducer = {
  auth: produce((auth, action) => {
    const { type, payload } = action;

    if (type === actionTypes.LOGIN_START) {
      auth.loading = true;
    }

    if (type === actionTypes.LOGIN_SUCCESS) {
      auth.loading = false;
      auth.success = true;
      auth.error = undefined;
      auth.authorized = true;
    }

    if (type === actionTypes.LOGIN_ERROR) {
      auth.loading = false;
      auth.success = false;
      auth.error = payload;
    }
    if (type === actionTypes.LOGOUT) {
      auth.authorized = false;
    }

    if (type === actionTypes.TOKEN_LOAD) {
      const { token, userId } = payload;
      auth.token = token;
      auth.userId = userId;
    }

    if (type === actionTypes.TOKEN_UNLOAD) {
      auth.token = undefined;
      auth.userId = undefined;
    }

    if (type === actionTypes.LOAD_PERMISSIONS) {
      auth.permissions = payload;
    }
    if (type === actionTypes.LOAD_PROFILE) {
      auth.profile = merge(auth.profile, payload);
    }
  }, {})
};


// Auth sagas
export function* sagas() {
  yield all([
    // check local storage for token and dispatch relogin
    restoreToken(),
    // login - error/logout repeating
    sagaRunner(loginFlow),
    renewTokenSaga(),
    sagaRunner(updateUserSettingsSaga),
    sagaRunner(updateUserPassword),
    sagaRunner(urlLoginRequestSaga),
    sagaRunner(urlLoginSaga)
  ]);
}

function* loginFlow() {
  const loginAction = yield take(actionTypes.LOGIN_START);
  const authoriseTask = yield fork(authorize, loginAction.payload);
  const action = yield take([
    actionTypes.LOGOUT,
    actionTypes.LOGIN_ERROR,
    serviceActionTypes.NETWORK_ERROR,
  ]);
  if (action.type === actionTypes.LOGOUT || action.type === actionTypes.LOGIN_ERROR) {
    yield cancel(authoriseTask);
    yield call(globals.Services.auth.logout);
    yield all([
      call(globals.Services.auth.clearPermissions),
      call(globals.Services.auth.clearProfile),
      call(globals.Services.auth.clearToken),
    ]);
  }
}

function* authorize({ username, password, tokenId, next }) {
  try {
    const response = tokenId
      ? yield call(globals.Services.auth.renewToken, { tokenId })
      : yield call(globals.Services.auth.requestToken, { username, password })
    // store permissions in local storage
    if (response.data.permissions) {
      yield all([
        call(globals.Services.auth.storePermissions, response.data.permissions),
        put({ type: actionTypes.LOAD_PERMISSIONS, payload: response.data.permissions }),
      ])
    } else {
      // try to reload existing permissions
      const permissions = yield call(globals.Services.auth.getPermissions);
      if (permissions) {
        yield put({ type: actionTypes.LOAD_PERMISSIONS, payload: permissions });
      }
    }
    // store profile in local storage
    if (response.data.profile) {
      yield all([
        call(globals.Services.auth.storeProfile, response.data.profile),
        put({ type: actionTypes.LOAD_PROFILE, payload: response.data.profile }),
      ])
    } else {
      // try to reload existing profile
      const profile = yield call(globals.Services.auth.getProfile);
      if (profile) {
        yield put({ type: actionTypes.LOAD_PROFILE, payload: profile });
      }
    }

    yield all([
      call(globals.Services.auth.storeToken, {
        tokenId: response.data.id,
        token: response.data.token,
        userId: response.data.user,
        expires: response.data.expires_after,
      }),
      yield put({ type: actionTypes.LOGIN_SUCCESS, payload: response.data }),
      yield put(routerActions.navigateTo(
        next ? next.params : {}
      ))
    ]);
  } catch (error) {
    if (error.response === undefined && 'request' in error) {
      yield put({ type: serviceActionTypes.NETWORK_ERROR, payload: true });
    } else {
      yield put({ type: actionTypes.LOGIN_ERROR, payload: error });
    }
  } finally {
    // Login attempted at least once
    yield put(actions.authReady());
    if (yield cancelled()) {
      // TODO handle canelled if needed
    }

  }
}

function* restoreToken() {
  const oldTokenId = yield call(globals.Services.auth.getTokenId);
  if (oldTokenId) {
    const next = yield select(selectors.next);
    yield put(actions.loginUsingToken(oldTokenId, next));
  } else {
    // No token to be restored
    yield put({ type: actionTypes.READY });
  }
}

function* renewTokenSaga() {
  const loginSuccessAction = yield take(actionTypes.LOGIN_SUCCESS);
  let expires = new Date(loginSuccessAction.payload.expires_after);
  yield sagaRunner(function* () {
    yield delay(expires - new Date() - 60 * 1000);
    const tokenId = yield call(globals.Services.auth.getTokenId);
    if (tokenId) {
      const response = yield call(globals.Services.auth.renewToken, { tokenId: tokenId });
      yield call(globals.Services.auth.storeToken, {
        expires: response.data.expires_after
      });
      expires = new Date(response.data.expires_after);
    } else {
      expires = new Date(expires.getTime() + 5 * 60 * 1000);
    }
  });
}


export function* updateUserSettingsSaga() {
  const confirmAction = yield take(actionTypes.UPDATE_USER_SETTINGS);
  const response = yield call(
    globals.Services.auth.updateProfile, confirmAction.payload
  );
  if (response.status === 204) {
    yield all([
      put(serviceActions.showNotification(
        [{ id: 'toast_profile_updated_title' }],
        [{ id: 'toast_profile_updated_body' }]
      )),
      put({ type: actionTypes.LOAD_PROFILE, payload: confirmAction.payload }),
    ]);
  }
}


export function* urlLoginRequestSaga() {
  try {
    const requestAction = yield take(actionTypes.REQUEST_LOGIN_URL);
    const response = yield call(
      globals.Services.auth.requestLoginUrl, requestAction.payload);
    if (response.status === 204) {
      yield put(serviceActions.showNotification(
        [{ id: `toast_url_login_title` }],
        [{ id: 'toast_url_login_sent' }]
      ));
    }
  } catch (error) {
    if (error.response && error.response.status == '400') {
      yield put(serviceActions.showNotification(
        [{ id: `toast_url_login_title` }],
        [{ id: 'toast_url_login_email_not_found' }]
      ));
    } else {
      throw error;
    }
  }
}

export function* urlLoginSaga() {
  const loginNavigationAction = yield take(routerActionTypes.TRANSITION_SUCCESS);
  const navigationParameters = loginNavigationAction.payload.route.params || {};
  if ('id' in navigationParameters && 'token' in navigationParameters) {
    yield call(globals.Services.auth.storeToken, {
      tokenId: navigationParameters.id,
      token: navigationParameters.token,
    });
    yield put(actions.loginUsingToken(navigationParameters.id));
  }
}